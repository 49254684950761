import {initializeApp, FirebaseApp} from "@firebase/app"


const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class Firebase {
    app: FirebaseApp | undefined;

    constructor(noop: boolean = false) {
        if (!noop) {
            this.app = initializeApp(firebaseConfig)
        }
    }
}
export default Firebase;
